<a href="https://www.croixmariebourdon.fr" target="_self" class="logo-cmb">
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 160.6 71.3"
    style="enable-background:new 0 0 160.6 71.3;"
    xml:space="preserve"
  >
    <style type="text/css">
      .stcmb {
        fill: #f9423a;
      }
    </style>
    <path
      class="stcmb"
      d="M7.4,52.1C6.7,53.3,5.4,54,4,54c-2.2,0-4-1.8-4-4c0,0,0-0.1,0-0.1c-0.1-2.2,1.6-4,3.7-4.1
	c1.3-0.1,2.6,0.6,3.4,1.7l-1.1,1c-0.5-0.7-1.2-1.2-2.1-1.2c-1.4,0-2.6,1.2-2.6,2.7c0,1.4,1.2,2.6,2.7,2.5c0.9,0,1.7-0.4,2.2-1.2
	L7.4,52.1z"
    />
    <path
      class="stcmb"
      d="M14.5,47.3h-0.6c-0.8,0.1-1.6,0.6-2,1.3v5.2h-1.5v-7.9h1.5v0.9c0.5-0.7,1.4-1.1,2.3-1.1h0.6L14.5,47.3z"
    />
    <path
      class="stcmb"
      d="M20.7,52.5c1.4,0.1,2.7-1,2.8-2.4s-1-2.7-2.4-2.8c-1.4-0.1-2.7,1-2.8,2.4c0,0.1,0,0.1,0,0.2
	c-0.1,1.3,0.9,2.5,2.2,2.6C20.6,52.5,20.6,52.5,20.7,52.5 M20.7,45.8c2.3-0.1,4.2,1.6,4.3,3.9c0.1,2.3-1.6,4.2-3.9,4.3
	s-4.2-1.6-4.3-3.9c0-0.1,0-0.1,0-0.2c-0.1-2.2,1.5-4,3.7-4.1C20.6,45.8,20.6,45.8,20.7,45.8"
    />
    <path
      class="stcmb"
      d="M27.9,45.9h1.5v7.9h-1.5V45.9z M28.7,42c0.6,0,1,0.4,1,1c0,0,0,0.1,0,0.1c0,0.6-0.4,1-1,1s-1-0.4-1-1
	c-0.1-0.5,0.3-1,0.9-1.1C28.6,42,28.7,42,28.7,42"
    />
    <polygon
      class="stcmb"
      points="36,50.8 34,53.8 32.2,53.8 35.1,49.7 32.5,45.9 34.3,45.9 36,48.5 37.8,45.9 39.6,45.9 37,49.7 
	39.9,53.8 38.1,53.8 "
    />
    <path
      class="stcmb"
      d="M44.2,45.9v0.8c0.6-0.6,1.4-0.9,2.2-0.9c1.1,0,2,0.5,2.7,1.3c0.7-0.8,1.8-1.3,2.9-1.3c1.1,0,2,0.5,2.7,1.3
	c0.5,0.8,0.8,1.7,0.7,2.7v4h-1.6v-4.3c0.1-0.6-0.1-1.1-0.4-1.6c-0.3-0.4-0.9-0.7-1.4-0.7c-0.9,0-1.7,0.4-2.3,1.1
	c0.1,0.5,0.1,0.9,0.1,1.4v4h-1.6v-4.2c0.1-0.6-0.1-1.1-0.4-1.6c-0.4-0.5-0.9-0.7-1.5-0.7c-0.8,0-1.6,0.4-2.1,1.1v5.4h-1.5v-7.8H44.2
	z"
    />
    <path
      class="stcmb"
      d="M64.5,48.4c-0.5-0.7-1.3-1.1-2.1-1.1c-1.4,0.1-2.5,1.2-2.4,2.6c0,0,0,0,0,0c-0.1,1.4,1,2.5,2.4,2.6
	c0,0,0.1,0,0.1,0c0.8,0,1.6-0.4,2.1-1.1L64.5,48.4z M64.5,53.1c-0.6,0.6-1.4,0.9-2.3,0.9c-0.7,0-1.3-0.2-1.9-0.5c-2-1.3-2.5-4-1.2-6
	c0.3-0.5,0.8-0.9,1.2-1.2c1.3-0.8,3-0.6,4.2,0.4V46H66v7.9h-1.4L64.5,53.1z"
    />
    <path
      class="stcmb"
      d="M74.1,47.3h-0.6c-0.8,0.1-1.6,0.6-2,1.3v5.2H70v-7.9h1.5v0.9c0.5-0.7,1.4-1.1,2.3-1.1h0.6L74.1,47.3z"
    />
    <path
      class="stcmb"
      d="M77.4,45.9h1.5v7.9h-1.5V45.9z M78.2,42c0.6,0,1,0.4,1,1c0,0,0,0.1,0,0.1c0,0.6-0.4,1-1,1s-1-0.4-1-1
	c-0.1-0.5,0.3-1,0.9-1.1C78.1,42,78.2,42,78.2,42"
    />
    <path
      class="stcmb"
      d="M83.8,48.9h4.1c-0.2-1-1-1.7-2-1.7C84.9,47.2,84,47.9,83.8,48.9 M83.7,50.2c0.1,1.3,1.1,2.3,2.4,2.4
	c0.9,0,1.7-0.4,2.2-1.1l1,1c-1.4,1.7-3.9,2-5.6,0.6c-1-0.8-1.5-1.9-1.5-3.2c0-2.4,1.5-4.1,3.7-4.1s3.6,1.8,3.6,4.1
	c0,0.1,0,0.2,0,0.3L83.7,50.2z"
    />
    <path
      class="stcmb"
      d="M94.3,51.3c0.5,0.7,1.3,1.1,2.1,1.1c1.4,0,2.5-1.1,2.5-2.5c0,0,0-0.1,0-0.1c0.1-1.4-1-2.5-2.4-2.6c0,0,0,0,0,0
	c-0.8,0-1.6,0.4-2.1,1.1L94.3,51.3z M94.3,53.8h-1.5V41.9h1.5v4.7c1.2-1,2.9-1.2,4.2-0.4c1.2,0.8,1.9,2.2,1.9,3.6
	c0,1.4-0.7,2.8-1.9,3.6c-0.6,0.3-1.2,0.5-1.9,0.5c-0.9,0-1.7-0.3-2.3-0.9L94.3,53.8z"
    />
    <path
      class="stcmb"
      d="M106.9,52.5c1.4,0.1,2.7-1,2.8-2.4s-1-2.7-2.4-2.8c-1.4-0.1-2.7,1-2.8,2.4c0,0.1,0,0.1,0,0.2
	c-0.1,1.3,0.9,2.5,2.2,2.6C106.8,52.5,106.8,52.5,106.9,52.5 M106.9,45.8c2.3-0.1,4.2,1.6,4.3,3.9c0.1,2.3-1.6,4.2-3.9,4.3
	c-2.3,0.1-4.2-1.6-4.3-3.9c0-0.1,0-0.1,0-0.2c-0.1-2.2,1.5-4,3.7-4.1C106.8,45.8,106.8,45.8,106.9,45.8"
    />
    <path
      class="stcmb"
      d="M114.7,52.6c-0.5-0.8-0.8-1.7-0.7-2.7v-4h1.5v4.3c-0.1,0.6,0.1,1.1,0.4,1.6c0.4,0.5,0.9,0.7,1.5,0.7
	c0.8,0,1.6-0.4,2.1-1.1v-5.5h1.5v7.9h-1.5v-0.7c-1.3,1.3-3.4,1.2-4.7-0.1c-0.1-0.1-0.2-0.2-0.2-0.3"
    />
    <path
      class="stcmb"
      d="M129.1,47.3h-0.6c-0.8,0.1-1.6,0.6-2,1.3v5.2H125v-7.9h1.5v0.9c0.5-0.7,1.4-1.1,2.3-1.1h0.6L129.1,47.3z"
    />
    <path
      class="stcmb"
      d="M137.5,48.4c-0.5-0.7-1.3-1.1-2.1-1.1c-1.4,0.1-2.5,1.2-2.4,2.6c0,0,0,0,0,0c-0.1,1.4,1,2.5,2.4,2.6
	c0,0,0.1,0,0.1,0c0.8,0,1.6-0.4,2.1-1.1L137.5,48.4z M137.5,53.1c-0.6,0.6-1.4,0.9-2.3,0.9c-0.7,0-1.3-0.2-1.9-0.5
	c-2-1.3-2.5-4-1.2-6c0.3-0.5,0.8-0.9,1.2-1.2c1.3-0.8,3-0.6,4.2,0.4v-4.8h1.5v11.9h-1.5V53.1z"
    />
    <path
      class="stcmb"
      d="M146.2,52.5c1.4,0.1,2.7-1,2.8-2.4c0.1-1.4-1-2.7-2.4-2.8s-2.7,1-2.8,2.4c0,0.1,0,0.1,0,0.2
	c-0.1,1.3,0.9,2.5,2.2,2.6C146.1,52.5,146.1,52.5,146.2,52.5 M146.2,45.8c2.3-0.1,4.2,1.6,4.3,3.9c0.1,2.3-1.6,4.2-3.9,4.3
	s-4.2-1.6-4.3-3.9c0-0.1,0-0.1,0-0.2c-0.1-2.2,1.5-4,3.7-4.1C146.1,45.8,146.1,45.8,146.2,45.8"
    />
    <path
      class="stcmb"
      d="M154.9,46.7c0.6-0.6,1.4-0.9,2.3-0.9c1.1,0,2.1,0.5,2.7,1.4c0.5,0.8,0.8,1.8,0.7,2.8v3.9h-1.5v-4
	c0.1-0.6-0.1-1.3-0.4-1.8c-0.4-0.5-1-0.8-1.6-0.8c-0.9,0-1.7,0.4-2.2,1.1v5.4h-1.5v-7.9h1.5V46.7z"
    />
    <path
      class="stcmb"
      d="M6.1,65.7C5.6,65,4.8,64.6,4,64.6c-1.4,0.1-2.5,1.2-2.4,2.6c0,0,0,0,0,0c-0.1,1.4,1,2.5,2.4,2.6c0,0,0,0,0,0
	c0.8,0,1.6-0.4,2.1-1.1V65.7z M6.1,70.4c-0.6,0.6-1.4,0.9-2.3,0.9c-0.7,0-1.3-0.2-1.9-0.5c-2-1.3-2.5-4-1.2-6
	c0.3-0.5,0.8-0.9,1.2-1.2c0.5-0.3,1.2-0.5,1.8-0.5c0.9,0,1.7,0.3,2.4,0.9v-0.7h1.5v7.9H6.1V70.4z"
    />
    <path
      class="stcmb"
      d="M15.1,64.7h-0.6c-0.8,0.1-1.6,0.6-2,1.3v5.2h-1.6v-8h1.5v0.9c0.5-0.7,1.4-1.1,2.3-1.1h0.6L15.1,64.7z"
    />
    <path
      class="stcmb"
      d="M24.1,69.4c-1.2,2-3.8,2.6-5.8,1.4S15.7,67,16.9,65c1.2-2,3.8-2.6,5.8-1.4c0.5,0.3,0.9,0.6,1.2,1.1l-1.1,1
	c-0.5-0.7-1.2-1.2-2.1-1.2c-1.4,0-2.6,1.2-2.5,2.7c0,1.4,1.2,2.6,2.6,2.5c0.9,0,1.7-0.5,2.2-1.2L24.1,69.4z"
    />
    <path
      class="stcmb"
      d="M27.9,63.9c0.6-0.6,1.4-0.9,2.2-0.9c1.1,0,2.1,0.5,2.7,1.4c0.5,0.8,0.8,1.8,0.7,2.8v3.9h-1.4v-3.9
	c0.1-0.6-0.1-1.3-0.4-1.8c-0.4-0.5-1-0.8-1.6-0.8c-0.9,0-1.7,0.4-2.2,1.1v5.4h-1.5V59.2h1.5V63.9z"
    />
    <path
      class="stcmb"
      d="M36.8,63.2h1.5v7.9h-1.5V63.2z M37.6,59.3c0.6,0,1,0.4,1,1c0,0,0,0.1,0,0.1c0,0.6-0.4,1-1,1s-1-0.4-1-1
	c-0.1-0.5,0.3-1,0.9-1.1C37.5,59.3,37.6,59.3,37.6,59.3"
    />
    <path
      class="stcmb"
      d="M46.2,71.1L45,71.3c-0.8,0-1.6-0.3-2.1-0.9c-0.4-0.6-0.7-1.4-0.6-2.1v-3.6h-1.9v-1.5h1.9v-2.1h1.5v2.2h2.4v1.4
	h-2.4v3.7c0,0.3,0,0.7,0.2,1c0.3,0.3,0.6,0.4,1,0.4h0.8L46.2,71.1z"
    />
    <path
      class="stcmb"
      d="M49.6,66.2h4.1c-0.2-1-1-1.7-2-1.7C50.7,64.5,49.8,65.2,49.6,66.2 M49.5,67.5c0.1,1.3,1.1,2.3,2.4,2.4
	c0.9,0,1.7-0.4,2.2-1.1l1,1c-0.8,1-2,1.5-3.2,1.5c-2.2,0-3.9-1.7-3.9-3.9c0-0.1,0-0.1,0-0.2c0-2.4,1.5-4.1,3.7-4.1s3.6,1.8,3.6,4.1
	c0,0.1,0,0.2,0,0.3H49.5z"
    />
    <path
      class="stcmb"
      d="M64.7,69.4c-1.2,2-3.8,2.6-5.8,1.4c-2-1.2-2.6-3.8-1.4-5.8c1.2-2,3.8-2.6,5.8-1.4c0.5,0.3,0.9,0.6,1.2,1.1
	l-1.1,1c-0.5-0.7-1.2-1.2-2.1-1.2c-1.4,0-2.6,1.2-2.5,2.6c0,1.4,1.2,2.6,2.6,2.6c0.9,0,1.7-0.5,2.2-1.2L64.7,69.4z"
    />
    <path
      class="stcmb"
      d="M71.7,71.1l-1.2,0.2c-0.8,0-1.6-0.3-2.1-0.9c-0.4-0.6-0.7-1.4-0.6-2.1v-3.6h-2v-1.5h1.9v-2.1h1.5v2.2h2.4v1.4
	h-2.4v3.7c0,0.3,0,0.7,0.2,1c0.3,0.3,0.6,0.4,1,0.4h0.8L71.7,71.1z"
    />
    <path
      class="stcmb"
      d="M75,66.2h4.1c-0.2-1-1-1.7-2-1.7C76.1,64.5,75.2,65.2,75,66.2 M74.9,67.5c0.1,1.3,1.1,2.3,2.4,2.4
	c0.9,0,1.7-0.4,2.2-1.1l1,1c-0.8,1-2,1.5-3.2,1.5c-2.2,0-3.9-1.7-3.9-3.9c0-0.1,0-0.1,0-0.2c0-2.4,1.5-4.1,3.7-4.1s3.6,1.8,3.6,4.1
	c0,0.1,0,0.2,0,0.3L74.9,67.5z"
    />
    <path
      class="stcmb"
      d="M83.4,69c0.6,0.5,1.4,0.9,2.2,0.9c0.7,0,1.3-0.4,1.3-1s-1.2-1-2.4-1.3c-0.9-0.2-1.6-1-1.7-2
	c0-1.4,1.1-2.5,2.5-2.5c0.1,0,0.2,0,0.3,0c0.9,0,1.8,0.3,2.5,0.9l-0.8,1.1c-0.5-0.4-1-0.6-1.6-0.6c-0.7,0-1.4,0.4-1.4,1
	s0.6,0.7,1.3,0.9s2.7,0.9,2.7,2.5c-0.1,1.4-1.4,2.5-2.8,2.4c0,0,0,0,0,0c-1.1,0-2.2-0.4-3-1.1L83.4,69z"
    />
    <path
      class="stcmb"
      d="M101.6,65.7c-0.5-0.7-1.3-1.1-2.2-1.1c-1.4,0.1-2.5,1.2-2.4,2.6c0,0,0,0,0,0c-0.1,1.4,1,2.5,2.4,2.6
	c0,0,0.1,0,0.1,0c0.8,0,1.6-0.4,2.1-1.1V65.7z M101.6,70.4c-0.6,0.6-1.4,0.9-2.3,0.9c-0.7,0-1.3-0.2-1.9-0.5c-2-1.3-2.5-4-1.2-6
	c0.3-0.5,0.8-0.9,1.2-1.2c0.5-0.3,1.2-0.5,1.8-0.5c0.9,0,1.7,0.3,2.4,0.9v-0.7h1.5v7.9h-1.5V70.4z"
    />
    <path
      class="stcmb"
      d="M106.5,69c0.6,0.5,1.4,0.9,2.2,0.9c0.7,0,1.3-0.4,1.3-1s-1.2-1-2.4-1.3c-0.9-0.2-1.6-1-1.7-2
	c0-1.4,1.1-2.5,2.5-2.5c0.1,0,0.2,0,0.3,0c0.9,0,1.8,0.3,2.5,0.9l-0.8,1.1c-0.5-0.4-1-0.6-1.6-0.6c-0.7,0-1.4,0.4-1.4,1
	s0.6,0.7,1.3,0.9s2.7,0.9,2.7,2.5c-0.1,1.4-1.4,2.5-2.8,2.4c0,0,0,0,0,0c-1.1,0-2.2-0.4-3-1.1L106.5,69z"
    />
    <path
      class="stcmb"
      d="M114.3,69c0.6,0.5,1.4,0.9,2.2,0.9c0.7,0,1.3-0.4,1.3-1s-1.2-1-2.4-1.3c-0.9-0.2-1.6-1-1.7-2
	c0-1.4,1.1-2.5,2.5-2.5c0.1,0,0.2,0,0.3,0c0.9,0,1.8,0.3,2.5,0.9l-0.8,1.1c-0.5-0.4-1-0.6-1.6-0.6c-0.7,0-1.4,0.4-1.4,1
	s0.6,0.7,1.3,0.9s2.7,0.9,2.7,2.5c-0.1,1.4-1.4,2.5-2.8,2.4c0,0,0,0,0,0c-1.1,0-2.2-0.4-3-1.1L114.3,69z"
    />
    <path
      class="stcmb"
      d="M125.4,69.8c1.4,0.1,2.7-1,2.8-2.4c0.1-1.4-1-2.7-2.4-2.8s-2.7,1-2.8,2.4c0,0.1,0,0.1,0,0.2
	c-0.1,1.3,0.9,2.5,2.2,2.6C125.3,69.8,125.3,69.8,125.4,69.8 M125.4,63.1c2.3-0.1,4.2,1.6,4.3,3.9s-1.6,4.2-3.9,4.3
	c-2.3,0.1-4.2-1.6-4.3-3.9c0-0.1,0-0.1,0-0.2c-0.1-2.2,1.5-4,3.7-4.1C125.3,63.1,125.3,63.1,125.4,63.1"
    />
    <path
      class="stcmb"
      d="M138.7,69.4c-1.2,2-3.8,2.6-5.8,1.4c-2-1.2-2.6-3.8-1.4-5.8c1.2-2,3.8-2.6,5.8-1.4c0.5,0.3,0.9,0.6,1.2,1.1
	l-1.1,1c-0.5-0.7-1.2-1.2-2.1-1.2c-1.4,0-2.6,1.2-2.6,2.7c0,1.4,1.2,2.6,2.7,2.5c0.9,0,1.7-0.5,2.2-1.2L138.7,69.4z"
    />
    <path
      class="stcmb"
      d="M141,63.2h1.5v7.9H141V63.2z M141.8,59.3c0.6,0,1,0.4,1,1c0,0,0,0.1,0,0.1c0,0.6-0.4,1-1,1s-1-0.4-1-1
	c-0.1-0.5,0.3-1,0.9-1.1C141.7,59.3,141.8,59.3,141.8,59.3"
    />
    <path
      class="stcmb"
      d="M148.3,61.9l1-2.5h1.7l-1.7,2.5H148.3z M146.8,66.2h4.1c-0.2-1-1-1.7-2-1.7C147.9,64.5,147,65.2,146.8,66.2
	 M146.7,67.5c0.1,1.3,1.1,2.3,2.4,2.4c0.9,0,1.7-0.4,2.2-1.1l1,1c-1.4,1.7-3.9,2-5.6,0.6c-1-0.8-1.5-1.9-1.5-3.2
	c0-2.4,1.5-4.1,3.7-4.1s3.6,1.8,3.6,4.1c0,0.1,0,0.2,0,0.3L146.7,67.5z"
    />
    <path
      class="stcmb"
      d="M155.2,69c0.6,0.5,1.4,0.9,2.2,0.9c0.7,0,1.3-0.4,1.3-1s-1.2-1-2.4-1.3c-0.9-0.2-1.6-1-1.7-2
	c0-1.4,1.1-2.5,2.5-2.5c0.1,0,0.2,0,0.3,0c0.9,0,1.8,0.3,2.5,0.9l-0.8,1.1c-0.5-0.4-1-0.6-1.6-0.6c-0.7,0-1.4,0.4-1.4,1
	s0.6,0.7,1.3,0.9s2.7,0.9,2.7,2.5c-0.1,1.4-1.4,2.5-2.8,2.4c0,0,0,0,0,0c-1.1,0-2.2-0.4-3-1.1L155.2,69z"
    />
    <path
      class="stcmb"
      d="M74.7,0.1h-0.8C65.5,0,58.7,6.6,58.5,15c0.2,8.4,7,15,15.4,14.9h0.8L74.7,0.1z M60.9,10.4
	c2-5.1,6.8-8.5,12.2-8.8v8.8L60.9,10.4z M73.1,28.4c-7.2-0.3-12.9-6.2-13-13.4c0-1,0.1-2.1,0.4-3.1h12.6V28.4z"
    />
    <path
      class="stcmb"
      d="M91.9,0H77v30.2h15c5.5,0.1,10.1-4.3,10.2-9.8c0,0,0-0.1,0-0.1c0-1.8-0.5-3.6-1.5-5.2c1-1.6,1.5-3.4,1.5-5.2
	c-0.1-5.5-4.6-10-10.1-9.9C92,0,92,0,91.9,0 M99.5,13.8c-1.9-2.1-4.6-3.4-7.5-3.4H78.5V1.5h13.4c4.7-0.1,8.5,3.7,8.6,8.4
	c0,0,0,0,0,0C100.5,11.3,100.2,12.6,99.5,13.8 M78.5,18.3v-6.4h13.4c2.6,0,5.1,1.2,6.8,3.2c-1.6,2-4.1,3.2-6.7,3.2H78.5z M92.7,28.6
	v-8.8c2.6-0.2,5.1-1.4,6.9-3.3c0.7,1.2,1,2.5,1,3.9c-0.1,4.4-3.5,7.9-7.8,8.3 M78.5,19.8h12.7v8.8H78.5V19.8z"
    />
  </svg>
</a>

<style lang="scss">
  .logo-cmb {
    position: fixed;
    top: 60px;
    left: 26px;
  }

  .logo-cmb svg {
    margin-left: 26px;
    width: 147px;
    height: auto;
  }

  @media (max-width: 820px) {
    .logo-cmb {
      top: 24px;
      left: 16px;
      z-index: 2;
    }

    .logo-cmb svg {
      margin-left: 0;
      width: 60px;
    }
    svg {
      * {
        fill: #fff;
      }
    }
  }
</style>
