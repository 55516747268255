<script lang="ts">
	import { COLS, ROWS } from "../../utils";
	import { Tile } from "../board";
	export let visible: boolean;
</script>
<div class="int">
	<h3>comment jouer à REGAIN</h3><br>
	<div>Vous disposez de 6 essais pour deviner le MOT.<br>
		Chaque réponse doit contenir le bon nombre <br>de lettres (4 à 8) 
		et être correctement orthographié.<br><br>
		Appuyez sur le bouton "ENTRER" pour valider.<br><br>
		Après chaque réponse, un système de couleurs indiquera
		si elle sont bien placées ou non dans le mot, ou si elles n’y figurent pas.<br>
	</div>
	<div class:complete={visible} class="examples">
		<div><strong>Exemples</strong></div>
		<div class="row">
			<Tile value="w" state="🟩" />
			<Tile value="e" state="🔳" />
			<Tile value="a" state="🔳" />
			<Tile value="r" state="🔳" />
			<Tile value="y" state="🔳" />
		</div>
		<div>La lettre <strong>W</strong> est bien placée.</div>
		<div class="row">
			<Tile value="p" state="🔳" />
			<Tile value="i" state="🟨" />
			<Tile value="l" state="🔳" />
			<Tile value="l" state="🔳" />
			<Tile value="s" state="🔳" />
		</div>
		<div>La lettre <strong>I</strong> est présente mais mal placée.</div>
		<div class="row">
			<Tile value="v" state="🔳" />
			<Tile value="a" state="🔳" />
			<Tile value="g" state="🔳" />
			<Tile value="u" state="⬛" />
			<Tile value="e" state="🔳" />
		</div>
		<div>La lettre <strong>U</strong> n’est pas présente dans le mot.</div>
	</div>
</div>

<style lang="scss">
	div {
		margin: 14px 0;
	}
	.examples {
		// border-top: 1px solid var(--border-primary);
		// border-bottom: 1px solid var(--border-primary);
		:global(.row > *) {
			height: 100%;
			aspect-ratio: 1;
		}
		&:not(.complete) :global(.row .back) {
			transition-delay: 0.3s;
		}
	}
	.row {
		height: 40px;
		display: flex;
		gap: 4px;
	}
</style>
