<script lang="ts">
	import { onDestroy } from "svelte";

	import { mode } from "../../stores";

	import { DELAY_INCREMENT, ROWS } from "../../utils";

	export let value = "";
	export let state: LetterState;
	export let position = 0;
	export function bounce() {
		setTimeout(() => (animation = "bounce"), (ROWS + position) * DELAY_INCREMENT);
	}
	let s: string;
	let pop = false;
	let animation = "";

	// reset animation when value changes, because for some reason changing anination to "" when the game is over causes the tiles to flash
	$: !value && (animation = "");

	// ensure all animations play
	const unsub = mode.subscribe(() => {
		animation = "";
		s = "🔳";
		setTimeout(() => (s = ""), 10);
	});
	// prevent pop animation from playing at the beginning
	setTimeout(() => (pop = true), 200);

	onDestroy(unsub);
</script>

<div
	data-animation={animation}
	class:value
	class:pop
	class="tile {state} {s}"
	style="transition-delay: {position * DELAY_INCREMENT}ms"
>
	<div class="front">{value}</div>
	<div class="back">{value}</div>
</div>

<style lang="scss">
	:not(.pop),
	:global(.complete) .value {
		scale: 1 !important;
		opacity: 1 !important;
	}
	.value {
		.front {
			border-color: #fff;
		}
	}
	.tile {
		scale: 1 !important;
		display: inline;
		font-size: 2rem;
		font-weight: bold;
		text-transform: uppercase;
		position: relative;
		transform-style: preserve-3d;

		&[data-animation="bounce"] {
			animation: bounce 1s;
		}
		margin: 0.4vh;

		height: 5vh;
		width: 5vh;

	}

	@media (max-width: 400px) and (max-height: 844px) {   // Iphone 6
		.tile {
			aspect-ratio: 1;
		}
	}
	@media (min-height:1000px) and (min-width: 1200px) {   // Iphone 6
		.tile {
			height: 4vh;
			width: 4vh;
		}
	}
	@media (min-height:2000px) and (min-width: 1200px) {   // Iphone 6
		.tile {
			height: 2.5vh;
			width: 2.5vh;
			margin: 0.2vh;

		}
	}
	




	.back,
	.front {
		display: grid;
		place-items: center;
		position: absolute;
		backface-visibility: hidden;
		-webkit-backface-visibility: hidden;
		transition: transform 0s ease-in-out;
		border-radius: 5px;
		font-size: 1.4rem;
		color: #fff;
		
		width: 100%;
		height: 100%;

	}



	.front {
		border: 2px solid rgba(255, 255, 255, 0.414);
		transform: rotateX(0deg);
		color: #fff;
	}
	.back {
		// background: rgba(255, 255, 255, 0.699);
		background-color: #fff;
		color: var(--blue);
		border: 2px solid #fff;
		transform: rotateX(180deg);
		border-width: 3px;
	}

	@media (max-width: 375px) and (max-height: 700px) {  

		.back,
		.front {
			font-size: 15px;
			border-width: 2px !important;
		}

	}





	.🟩 .back {
		background: var(--color-correct);
		color: var(--blue);
		border-color: var(--color-correct);
	}

	.🟨 .back {
		color: #ff00ff;
		border-color: #ff00ff;
		border-radius: 50%;
		border-width: 3px;

	}
	:global(.complete) .tile:not(.🔳) .front {
		transition-delay: inherit !important;
		transition-duration: 0s;
		transform: rotateX(180deg);
	}
	:global(.complete) .tile:not(.🔳) .back {
		transition-delay: inherit !important;
		transition-duration: 0s;
		transform: rotateX(0deg);
	}


	
	@keyframes pop {
		from {
			scale: 0.8;
			opacity: 0;
		}

		40% {
			scale: 1.1;
			opacity: 1;
		}
	}
	@keyframes bounce {
		0%,
		20% {
			transform: translateY(0);
		}
		40% {
			transform: translateY(-30px);
		}
		50% {
			transform: translateY(5px);
		}
		60% {
			transform: translateY(-15px);
		}
		80% {
			transform: translateY(2px);
		}
		100% {
			transform: translateY(0);
		}
	}
</style>
