<script lang="ts">
  export let visible: boolean;
  const currentYear = new Date().getFullYear();
</script>

<div class="int" meta-visible="{visible}">
  <div class="wrapper">
    <div class="logo">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xml:space="preserve"
        x="0"
        y="0"
        style="enable-background:new 0 0 287.1 53.6"
        version="1.0"
        viewBox="0 0 287.1 53.6"
        ><path
          id="r"
          d="M25.3 24.7c.4-.5.7-1.3.7-2.1s-.3-1.6-.7-2.1c-.4-.5-1.1-.9-2.5-.9h-4.1v6h4.1c1.4 0 2-.3 2.5-.9m10.5 18h-6.1l-9.2-12.3h-1.8v12.3h-5V14.9h9.4c2.6 0 4.3.8 5.6 2.1 1.4 1.4 2.3 3.5 2.3 5.7s-.9 4.3-2.3 5.7c-.7.7-1.5 1.3-2.6 1.6l9.7 12.7zm10.4-11c.1-4.1-.1-8.2-.2-12.4 0-2.5-.1-5.1-.2-7.6 0-.6-.1-1.2-.3-1.8l-.9-2.4c-.3-.7-.9-1.4-1.5-1.5-.9-.2-1.5-.6-2.1-1.2-.8-.9-1.8-1.5-3-1.6-.3 0-.7-.2-1-.4-1.1-.5-2.3-.9-3.6-1-1.2 0-2.3-.2-3.5-.5-1.2-.2-2.3-.5-3.5-.3-.2 0-.5 0-.7-.1-.3 0-.7-.1-1-.1-.7 0-1.3 0-2-.1-.6-.1-1.2-.4-1.8-.5-.9-.2-1.8-.4-2.8-.2-1.5.3-3 .5-4.6.5-.8 0-1.5 0-2.3.2-.8.3-1.7.3-2.6.4-.7.2-1.3.3-1.9.6-.1 0-.2.1-.3.1-1.2.1-2 .8-2.8 1.7-.4.4-.8.9-1.2 1.3-1 .9-1.5 2-1.5 3.5 0 1.8 0 3.5-.1 5.3 0 .9-.1 1.7-.2 2.6-.4 2.1-.4 4.2-.5 6.3-.1 2-.1 3.9 0 5.9 0 2.1.1 4.1.2 6.2 0 1.2 0 2.4.2 3.5.2 1.3.3 2.5.2 4.3 0 .5 0 1.5.2 2.5.2 1.3.7 2.7 1.1 4.1.2.6.5.9 1 .9.2 0 .5.1.5.2.1.4.5.4.7.6.3.2.7.3 1 .6.5.3 1 .4 1.6.2.4-.1.9 0 1.3.1.6.1 1.1.4 1.6.5 1.1.3 2.2.1 3.2-.1 1.5-.3 3-.3 4.5-.1.2 0 .4 0 .6-.1 1.2-.3 2.3-.2 3.5 0 .9.1 1.8.2 2.8.3.5 0 1.1-.1 1.6 0 .8.1 1.6.3 2.4.5.5.1 1 .2 1.4.2.8.1 1.7.1 2.5 0 1-.1 1.9-.4 2.8-.7.8-.3 1.5-.7 2.3-.9 1.1-.3 2.2-.5 3.3-.8.1 0 .3-.1.3-.2.3-.4.7-.7.9-1.2.2-.4.6-.8.9-1.2.4-.4.8-.7 1.1-1.2.4-.6.6-1.4.7-2.2.2-1.7.4-3.5.7-5.2.8-2.5.9-5 1-7.5"
        /><path
          id="e"
          d="M80.5 41.3H64.7V13.5h15.5v4.8H69.6V24h7.9v4.8h-7.9v7.8h10.9v4.7zM96 21c-.2-1.6-.5-3.3-.5-5 0-.8 0-1.7-.2-2.4-.3-1-.2-2-.4-2.9-.1-.7-.2-1.4-.5-2 0-.1-.1-.2-.1-.3-.1-1.3-.8-2.2-1.5-3-.4-.4-.9-.9-1.2-1.3-.8-1.1-1.9-1.6-3.2-1.6-1.6 0-3.3 0-4.9-.1-.8 0-1.6-.1-2.4-.2-1.9-.4-3.9-.5-5.8-.6-1.8-.1-3.6-.1-5.4 0-1.9 0-3.8.1-5.7.2-1.1 0-2.2 0-3.3.2-1.2.2-2.3.3-4 .2-.5 0-1.4 0-2.3.2-1.3.3-2.6.8-4 1.3-.5.2-.8.5-.8 1.1 0 .2-.1.5-.2.6-.4.1-.4.5-.5.8-.2.4-.3.8-.5 1.1-.3.5-.4 1.1-.2 1.7.1.4 0 1-.1 1.4-.1.6-.4 1.2-.5 1.8-.2 1.2-.1 2.3 0 3.5.2 1.6.3 3.3.1 4.9 0 .2 0 .5.1.7.3 1.3.1 2.5 0 3.8l-.3 3c0 .6.1 1.1 0 1.7-.1.9-.3 1.8-.4 2.6-.1.5-.2 1-.2 1.5-.1.9-.1 1.8 0 2.7.1 1.1.4 2.1.7 3.1.3.8.6 1.6.9 2.5.3 1.2.5 2.4.7 3.6 0 .1.1.3.1.4.4.3.7.8 1.2 1 .4.2.7.6 1.1 1 .4.4.7.9 1.1 1.2.6.5 1.3.6 2 .7 1.6.3 3.2.4 4.8.7 2.3.4 4.6.6 6.9.6 3.8.1 7.6-.1 11.5-.2 2.4-.1 4.7-.1 7.1-.2.6 0 1.1-.1 1.7-.3.7-.4 1.5-.7 2.2-1 .7-.3 1.3-.9 1.4-1.7.2-.9.5-1.6 1.1-2.3.8-.9 1.4-1.9 1.5-3.2 0-.4.2-.7.3-1.1.5-1.2.8-2.5.9-3.8 0-1.3.2-2.5.4-3.8.2-1.3.4-2.5.3-3.8 0-.2 0-.5.1-.8 0-.4.1-.7.1-1.1 0-.7 0-1.5.1-2.2.1-.7.4-1.3.5-2 .1-.9.3-1.8.2-2.9"
        /><g id="g"
          ><path
            d="M144.4 29.1c-.1-.6-.2-1.2-.2-1.9 0-1.2-.2-2.4-.6-3.6l-.3-1.2c-.2-.9-.2-1.9-.6-2.8-.3-.6-.6-1.1-.9-1.7-.4-1-.8-2.1-1.3-3-.1-.1-.1-.2-.1-.3-.1-.3-.3-.5-.4-.9.1-.6 0-.6-.4-1.3-.4-.8-.9-1.5-1.3-2.3-.6-1.3-1.5-2.1-2.5-3-.8-.8-1.8-1.4-2.6-2.2-.9-.9-2-1.2-3.1-1.6-.6-.2-1.2-.4-1.7-.7-1.6-.9-3.3-1.7-5.1-1.9h-.8c-.8-.2-1.7.1-2.5 0-.5-.1-1 .1-1.4 0-1-.3-1.9.1-2.7.6-.9.5-1.8 1.1-2.8 1.4l-1.2.3c-.4.1-.8.3-1.2.5-.9.5-1.7 1.1-2.5 1.6-1 .5-2 1.2-2.8 1.9-.5.5-1 .9-1.4 1.5-.4.5-.8 1.1-1.2 1.5-.5.5-.9 1-1.1 1.8-.2.8-.7 1.6-1 2.3-.3.5-.4 1.2-.5 1.8-.1.4-.2.8 0 1.3.2.3.2.7 0 1-.3.7-.6 1.4-.8 2.1-.7 2.4-1 4.9-1 7.4 0 .7 0 1.5.1 2.2.3 1.6.7 3.1.9 4.7.2.9.4 1.8.8 2.7.4.9.8 1.9 1.3 2.8.5 1.1 1.1 2.1 1.8 3 .5.6.9 1.1 1.3 1.8.4.6.8 1.2 1.4 1.6 1.1.8 2.1 1.6 3.2 2.5 1.8 1.5 3.8 2.4 6 3 1 .3 2 .5 3 .7 1.9.4 3.8.7 5.7 1.1.8.1 1.6-.2 2.4-.2l.2-.1c.4-.3.8-.3 1.3-.3s.9-.1 1.4-.2c.6-.2 1.2-.5 1.9-.7.7-.3 1.6-.3 2-1.1.1-.1.2-.1.3-.1.6-.3 1.3-.6 1.9-1 .6-.4 1.2-.9 1.9-1.2.8-.4 1.5-1 2.1-1.7.2-.3.4-.6.6-.8.6-.5 1-1.2 1.4-1.8.4-.6.7-1.2 1-1.9.5-1 1-1.9 1.3-2.9.4-1.2.7-2.4 1-3.6.1-.4.2-.9.3-1.3-.2-2.3-.4-4.1-.5-5.8zm-3 7c-.5 2.2-1.4 4.1-2.6 6-1 1.7-2.4 3.1-4.1 4.1-1.3.7-2.5 1.5-3.8 2.1-.4.2-.8.4-1.3.5-1.1.4-2.1.9-3.3.9-.4 0-.9.2-1.3.3-.6.2-1.3.2-1.9.1-2-.4-3.9-.7-5.9-1.2-2.5-.5-4.8-1.4-6.9-3.2-.7-.6-1.4-1.1-2.2-1.7-.8-.6-1.3-1.4-1.9-2.2-.1-.1-.1-.2-.2-.3-1.5-1.7-2.4-3.8-3.3-5.9-.4-1-.6-2.2-.9-3.3-.3-1.1-.5-2.2-.7-3.3-.1-.7-.2-1.5-.1-2.2.1-1.9.2-3.7.7-5.5.3-1 .6-1.9.9-2.9.6-1.7 1.3-3.4 2-5.1.5-1.2 1.2-2.3 1.8-3.4.4-.7 1.1-1.2 1.7-1.6l4.2-2.4c.2-.1.4-.2.6-.2 1.2-.2 2.3-.7 3.3-1.3 1-.5 2-.7 3.1-.7 1.2.1 2.5.1 3.7.3.7.1 1.4.3 2.1.6 1 .4 2 .9 3 1.4.6.2 1.1.4 1.7.7.6.3 1.1.6 1.6 1 1 .8 1.9 1.6 2.8 2.4.3.3.6.6.8.9l1.8 3c.3.5.4 1.1.6 1.6.7 1.6 1.5 3.1 2.2 4.7.3.6.4 1.2.6 1.8.2.6.3 1.3.4 2 .5 2 .8 4 .8 6 0 .8.1 1.5.2 2.3.4 1.3.1 2.5-.2 3.7z"
          /><path
            d="M122.2 30.2h6.6v6c-1.5 1.3-3.4 2.1-5.7 2.1-5 0-8.8-4.1-8.8-9.5 0-5.3 3.7-9.5 8.6-9.5 2.5 0 4.7 1.2 6.3 2.9l3.2-3.7c-2.3-2.4-5.6-4-9.4-4-8 0-13.6 6.3-13.6 14.3s5.6 14.3 13.7 14.3c4.7 0 8.5-2.2 10.8-5.3V25.4h-11.6v4.8z"
          /></g
        ><g id="a"
          ><path
            d="M188.5 7.8c-.5-.2-1.1-.3-1.7-.6-1.6-.7-3.3-.9-5.1-1-1.6 0-3.3 0-4.9-.1-1.6-.1-3.3-.2-4.9-.2h-11.4c-.6 0-1.2 0-1.8.2-2.3.6-4.6.9-6.9 1.1-.6 0-1.2.1-1.6.6-.4.6-.8 1.2-.9 1.9-.1.6-.2 1.3-.2 1.9-.2 1.9-.3 3.8-.4 5.7-.1 2.1-.1 4.2-.2 6.2-.1 1.8-.2 3.7-.2 5.5v5.1c.1 1 .2 1.9.1 2.9v.4c.1 1.4.3 2.8.4 4.2.1.6.2 1.1.8 1.3-.1.4 0 .8-.2 1.1-.3.6-.2 1.1 0 1.6.1.4.3.7.4 1.1.1.5.4.8.9.9 1.5.3 2.9.7 4.4 1 .7.1 1.5.1 2.2.1 2.1-.1 4.1-.2 6.2-.3h3.3c.4 0 .8-.1 1.2 0 1 .1 2.1.2 3.1.2.6 0 1.3-.1 2 0s1.4-.1 2.1-.2c1-.2 2-.3 3-.3.8 0 1.6-.1 2.4-.1.8-.1 1.5-.2 2.3-.2 1.6-.1 3.2-.1 4.8-.2 1-.1 1.1-.2 1.4-1.2.1-.2.1-.4.1-.6.1-2.1.1-4.3.2-6.4 0-2 .1-3.9.1-5.9 0-1.1.1-2.3-.1-3.4-.3-2.3-.4-4.6-.5-6.9-.1-1.8.3-3.5.2-5.3-.1-1.2 0-2.4 0-3.6-.2-2.1-.4-4.2-.6-6.5m-43 2c.5 0 .7-.3.7-.8.1-1.8 1.2-3.1 2.2-4.3.4-.4 1-.7 1.5-.7 1.5-.3 3-.4 4.5-.6 1.4-.2 2.7-.4 4-1 .5-.3 1.2-.2 1.8-.2.4 0 .7.1 1.1.1H174.6c2.2.4 4.4.1 6.5.3 1.6.2 3.2.1 4.8.6.8.3 1.7.7 2.5.9.7.2 1.4.4 2.2.5.6.1 1 .5 1 1.1v.9c-.3 2.2.1 4.3.3 6.5.1 1 .1 2 .1 2.9 0 1.6 0 3.1-.1 4.7 0 .8-.2 1.6-.2 2.4 0 1.5-.1 3.1.1 4.6.5 2.8.2 5.6.2 8.3 0 2.9-.1 5.8-.1 8.7 0 1.2-.1 2.3-.5 3.4 0 .1-.1.3-.1.4.1 1-.6 1.4-1.3 1.7-1 .4-2.1.4-3.1.5h-1.7c-1.2.1-2.4.2-3.6.2-1 .1-2 0-3 .1-.7 0-1.3.1-2 .2-.8.1-1.6.4-2.4.4-1.9 0-3.7-.1-5.6-.1-1 0-1.9-.1-2.9-.1H162c-1.3 0-2.6.1-3.9.2-1.2.2-2.4.3-3.6-.1-.7-.2-1.5-.2-2.2-.3-1-.2-1.9-.6-2.9-.9-.2-.1-.5-.2-.7-.2-.7-.1-1.1-.6-1-1.4 0-.3-.1-.7-.2-1-.3-.9-.6-1.7-.6-2.6 0-.7-.3-1.3-.4-2-.4-2.4-.7-4.7-.6-7.1 0-.4-.1-.7-.1-1.1-.1-1.6-.2-3.2-.2-4.8 0-2.4 0-4.7.1-7.1 0-2.3.1-4.7.2-7 0-1.2.1-2.3.2-3.5v-2.1c-.3-.2-.5-.4-.6-.6"
          /><path
            d="M166.6 31.2h6.6l-3.3-8.2-3.3 8.2zm-9.4 11 11-27.8h3.5l11 27.8h-5.2l-2.4-6.2h-10.3l-2.4 6.2h-5.2z"
          /></g
        ><g id="i"
          ><path
            d="M216.5 3.8c-1.1-.1-2.1.1-3.1.7-1.1.6-2.2 1.1-3.3 1.3-.2 0-.4.1-.6.2l-4.2 2.4c-.7.4-1.3.9-1.7 1.6-.6 1.1-1.3 2.2-1.8 3.4-.7 1.7-1.4 3.4-2 5.1-.4.9-.7 1.9-.9 2.9-.5 1.8-.6 3.7-.7 5.5 0 .7 0 1.5.1 2.2.2 1.1.4 2.2.7 3.3.3 1.1.4 2.2.9 3.3.9 2.1 1.8 4.2 3.3 5.9.1.1.2.2.2.3.6.8 1.1 1.6 1.9 2.2.7.5 1.5 1.1 2.2 1.7 2 1.7 4.4 2.6 6.9 3.2 2 .4 3.9.8 5.9 1.2.7.1 1.3.1 1.9-.1.4-.1.9-.3 1.3-.3 1.2 0 2.2-.5 3.3-.9.4-.1.9-.3 1.3-.5 1.3-.7 2.5-1.4 3.8-2.1 1.7-1 3.1-2.4 4.1-4.1 1.1-1.8 2.1-3.8 2.6-6 .3-1.2.6-2.4.5-3.6 0-.8-.2-1.5-.2-2.3-.1-2-.3-4-.8-6-.2-.7-.3-1.3-.4-2l-.6-1.8c-.7-1.6-1.5-3.1-2.2-4.7-.2-.5-.4-1.1-.6-1.6l-1.8-3c-.2-.4-.5-.7-.8-.9-.9-.8-1.8-1.6-2.8-2.4-.5-.4-1.1-.7-1.6-1-.5-.3-1.1-.5-1.7-.7-1-.5-2-1-3-1.4-.7-.3-1.4-.5-2.1-.6-1.5-.3-2.8-.3-4-.4m20.5 9.9c.2.3.3.6.4.9 0 .1.1.2.1.3.6.9.9 2 1.3 3 .3.6.6 1.1.9 1.7.4.9.4 1.9.6 2.8.1.4.1.8.3 1.2.3 1.2.5 2.3.6 3.6 0 .6.1 1.2.2 1.9.1 1.8.4 3.5.2 5.3 0 .5-.2.9-.3 1.3-.3 1.2-.6 2.4-1 3.6-.4 1-.9 2-1.3 2.9-.3.6-.6 1.3-1 1.9-.4.7-.8 1.3-1.4 1.8-.3.2-.4.5-.6.8-.6.7-1.3 1.3-2.1 1.7-.7.3-1.2.8-1.9 1.2-.6.4-1.3.7-1.9 1-.1.1-.3.1-.3.1-.5.8-1.3.8-2 1.1-.6.2-1.2.5-1.9.7-.4.2-.9.2-1.4.2-.4 0-.9 0-1.3.3l-.2.1c-.8.1-1.6.4-2.4.2-1.9-.4-3.8-.7-5.7-1.1-1-.2-2-.4-3-.7-2.2-.6-4.2-1.5-6-3-1-.9-2.1-1.7-3.2-2.5-.6-.4-1-1-1.4-1.6-.4-.6-.8-1.2-1.3-1.8-.8-.9-1.3-2-1.8-3-.5-.9-.9-1.8-1.3-2.8-.4-.9-.6-1.8-.8-2.7-.3-1.6-.7-3.1-.9-4.7-.1-.7-.1-1.4-.1-2.2.1-2.5.4-5 1-7.4.2-.7.5-1.4.8-2.1.2-.4.1-.7 0-1-.2-.4-.2-.8 0-1.3.2-.6.3-1.2.5-1.8.4-.8.8-1.5 1-2.3.2-.7.6-1.3 1.1-1.8.5-.4.8-1 1.2-1.5.4-.5.9-1 1.4-1.5.9-.8 1.8-1.4 2.8-1.9.9-.5 1.7-1.1 2.5-1.6.4-.2.8-.4 1.2-.5l1.2-.3c1-.3 1.9-.9 2.8-1.4.8-.4 1.7-.8 2.7-.6.4.1.9 0 1.4 0 .8.1 1.7-.1 2.5 0h.8c1.9.2 3.5.9 5.1 1.9.5.3 1.1.5 1.7.7 1.1.4 2.2.8 3.1 1.6.8.8 1.8 1.4 2.6 2.2 1 .9 1.9 1.8 2.5 3 .4.8.9 1.5 1.3 2.3.7 1.3.8 1.2.7 1.8"
          /><path d="M216.1 14.3h5v27.8h-5z" /></g
        ><g id="n"
          ><path
            d="M271.3 48.8c2.9.2 5.3 0 7.8-.5.8-.2 1.6-.4 2.2-1.2.2-.3.4-.7.6-1 .7-1.5 1.5-3 1.4-4.7-.1-4.1.2-8.2-.2-12.3-.4-3.6-.4-7.2-.7-10.8-.2-2.2-.7-4.4-1-6.6-.1-.6-.4-.9-.9-1-1.5-.4-3-.9-4.5-1.3-.4-.1-.8-.2-1.1-.2h-5.7c-1.1 0-2.2 0-3.3-.5-.3-.1-.6-.2-1-.2h-9.6c-1 0-2.1.1-3.1.4-1.1.4-2.1.8-3.3.8-1 0-1.3.4-1.5 1.4-.2 1-.3 2-.3 3.1-.1 2.3 0 4.6.3 6.9.3 2.1.3 4.3.3 6.5.1 2.9.5 5.7 1.1 8.5.4 1.7.8 3.3.9 5 0 .5.1 1 .3 1.4.5 1.2 1.2 2.3 1.8 3.5.3.7.9 1 1.5 1.3 1.1.4 2.3.6 3.4.6 1 0 2-.1 3 0 .7 0 1.3.2 2 .3.8.1 1.5.4 2.3.5 1.1.1 2.1.1 3.2.1h4.1M286 18.3l.6 8.1c.1.9 0 1.8.1 2.7 0 .4.2.7.2 1.1.1 1.9.2 3.8.2 5.7v5.5c0 1.2-.2 2.3-.6 3.4-.4 1-.7 2.1-1.2 3-.5 1.1-1.2 2-1.8 3-.1.1-.3.2-.4.3-.6.4-1.3.6-2 .9-.7.3-1.5.5-2.3.4-.3 0-.7.1-1.1.2-.8.1-1.6.4-2.4.4-2.9 0-5.7-.1-8.6-.1-.6 0-1.2.1-1.8.1-.5 0-.9-.1-1.4-.1-.8-.1-1.6-.1-2.3-.4-1.3-.5-2.5-.5-3.8-.4-2.1.1-4.2-.2-6.2-1.1-1-.4-1.7-1.1-2.3-1.9-.4-.5-.7-1.3-1-1.9-.5-.9-.9-1.9-1.3-2.8-.6-1.4-.8-3-.9-4.6 0-.7-.2-1.5-.4-2.1-.5-1.1-.7-2.3-.8-3.5-.2-2.1-.3-4.2-.6-6.3-.3-2-.1-3.9-.1-5.9 0-1.8-.2-3.5-.2-5.3 0-1.4-.2-2.9.2-4.3.1-.4 0-.9.1-1.3.1-1.3.3-2.5 1.4-3.4.2-.1.2-.4.4-.6.6-.8 1.4-.8 2.3-.8.6 0 1.2 0 1.7-.3 2.1-.9 4.3-1.2 6.6-1.1 1.9 0 3.9-.1 5.8-.1h3.5c.3 0 .5.1.8.2 1.2.6 2.5.5 3.7.5h5.8c.5 0 .9.2 1.4.3 1 .3 1.9.6 2.9.9.7.2 1.3.4 2 .5.9.1 1.4.8 2 1.2.3.2.4.7.5 1.2.3 1.3.5 2.6.7 3.9l.9 5.1-.3-.3z"
          /><path
            d="M255.1 14.8h3.5L272.9 33V14.8h5v27.9h-3.5l-14.3-18.2v18.2h-5z"
          /></g
        ></svg
      >
    </div>
    <h3>En {currentYear}, croixmariebourdon <br />vous invite à jouer !</h3>
    <br />
    <div>
      L’équipe a conçu le jeu REGAIN à partir <br />de sa démarche d’innovation
      environnementale.
    </div>
  </div>
</div>

<style lang="scss">
  .int {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2rem 0;
  }
  h3,
  div {
    text-transform: none;
    color: var(--blue);
  }
  svg {
    width: 60%;
    margin-bottom: 2rem;
  }
  svg path {
    fill: var(--blue);
  }
  div {
    margin: 14px 0;
    text-align: center;
  }
</style>
