<script lang="ts">
    import { familys } from "../../utils";
	export let word: string;

	function searchDefinition(searchWord:string): String {
		let result = '';
		for (const [key, value] of Object.entries(familys)) {
			value.words.forEach(word => {
				if(word.word == searchWord){
					if(word.texte_projet){
						result = word.texte_projet;
					}
				}
			});
		}
		return result;

	}
	let definition = searchDefinition(word);
</script>

<div class="def">
	<h3>Votre mot était</h3>
	<h2>« {word} »</h2>
	<div class="text-definition">{definition}</div>
</div>

<style>
	h2 {
		font-size: 2.5rem;
		text-transform: uppercase;
		letter-spacing: 0.2rem;
		margin-bottom: 1rem;

	}

	h3 {
		margin-bottom: 0;
	}

	@media (max-width: 375px) and (max-height: 700px) {  

		h2{
			font-size: 20px;
			letter-spacing: 1px;
		}

	}

	.text-definition{
		width: 80%;

	}
	/* ol {
		padding-left: 1.5rem;
	} */
	/* li {
		margin-bottom: 0.5rem;
	} */
	/* li::first-letter {
		text-transform: uppercase;
	}
	li::marker {
		color: var(--fg-secondary);
	} */
	.def{
		text-align: center;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
	}
</style>
