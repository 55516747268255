<script lang="ts">
	import { createEventDispatcher } from "svelte";

	export let letter: string;
	export let state: LetterState = "🔳";

	const dispatch = createEventDispatcher();
</script>

<div class={state} class:big={letter.length !== 1} on:keypress={()=>{}} on:click={() => dispatch("keystroke", letter)}>
	{letter}<slot />
</div>

<style>
	div {
		font-size: calc(var(--fs-tiny) + 1px);
		font-weight: bold;
		text-transform: uppercase;
		border-radius: 4px;
		height: 58px;


		background: var(--key-bg);
		cursor: pointer;

		display: flex;
		justify-content: center;
		align-items: center;
		
		flex: 1;
		transition: background-color 0.3s ease-in-out, height 0.2s;
		color: var(--blue);


	}




	@media (max-width: 500px) {  
		div{
			height: 6vh;
			margin: 2px;
		}
	}

	


	@media (min-width: 500px) and (max-height: 12024px) {  
		div{
			height: 6vh;
			margin: 2px;
		}
	}


	@media (min-height:1000px) and (min-width: 1200px) {
		div {
			height: 6vh;
		}
	}
	@media (min-height:2000px) and (min-width: 1200px) { 
		div {
			height: 2.5vh;
		}
	}





	:global(.guesses) div {
		transition-delay: 1s;
	}
	:global(.guesses .preventChange) div {
		transition-duration: 0.15s;
		transition-delay: 0s;
		background: var(--key-bg) !important;
	}
	.big {
		font-size: var(--fs-tiny);
		flex-grow: 1.5;
	}
	.⬛ {
		background: var(--color-absent);
	}
	.🟨 {
		background: var(--color-present);
	}
	.🟩 {
		background: var(--color-correct);
	}
</style>
