<script lang="ts">
	export let stat: number | string;
	export let name: string;
</script>

<section>
	<div class="stat">{stat}</div>
	<div class="name">{name}</div>
</section>

<style>
	section {
		display: flex;
		flex-direction: column;
		align-items: center;
		width: 84px;
	}
	.stat {
		font-size: 1.5rem;
		font-weight: 600;
	}
	.name {
		font-size: 1rem;
		text-align: center;
		text-transform: lowercase;
	}
</style>
