<script lang="ts">
	import { createEventDispatcher } from "svelte";

	import GameIcon from "./GameIcon.svelte";

	export let visible = false;
	export let fullscreen = false;

	export let classModal = '';

	const dispach = createEventDispatcher();

	import {
		resetTuto,
	} from "../utils";


	function close() {
		visible = false;
		dispach("close");
		resetTuto();
	}



</script>

{#if fullscreen}
	<div class:visible class="page">
		<div>
			<div class="exit" on:keypress={()=>{}} on:click={close}>
				<GameIcon>
					<path
						d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
					/>
				</GameIcon>
			</div>
			<slot />
		</div>
		<slot name="footer" />
	</div>
{:else}
	<div class:visible class="overlay {classModal}" on:keypress={()=>{}} on:click|self={close}>
		<div class="modal">
			<div class="exit" on:keypress={()=>{}} on:click={close}>
				<GameIcon>
					<path
						d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
					/>
				</GameIcon>
			</div>

			<slot />
		</div>
	</div>
{/if}

<style lang="scss">
	@mixin modal-outer() {
		transition: all 0.2s ease;
		font-size: var(--fs-small);
		visibility: hidden;
		position: absolute;
		z-index: 1;
		opacity: 0;
		inset: 0;
	}
	.overlay.visible,
	.visible.page,
	.visible .modal {
		top: 0;
		opacity: 1;
		visibility: visible;
		pointer-events: all;

	}
	.overlay{
		pointer-events: none;

	}
	.exit {
		position: absolute;
		top: var(--modal-padding);
		right: var(--modal-padding);
	}
	/* regular modal */
	.overlay {
		opacity: 0;
		position: absolute;
		left: 0;
		width: 100vw;
		height: calc(var(--vh, 1vh) * 100);
		background: rgba(255, 255, 255, 0.839);
		transition: 0.8s all;
	}
	.modal {
		--modal-padding: 16px;
		background: #87fe9d;

		width: var(--game-width);

		height: calc(var(--vh, 1vh) * 80);

		overflow-y: auto;
		border-radius: 8px;
		padding: var(--modal-padding);
		padding-top: calc(2 * var(--modal-padding));
		position: absolute;
		inset: 20vh 0 0;
		margin: auto;
		transition: all 0.3s ease;
		> :global(*:not(h3)) {
			margin-bottom: 15px;
		}
		color: #000;

		display: flex;
		flex-direction: column;
		justify-content: space-around;
		box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
	}

	@media (min-width: 500px) {  
		.modal {
			max-width: 900px;
			width: 80vw;
		}
		
	}
	@media (min-width: 499px) and (max-height:900px) {  
		.modal{
			height: calc(var(--vh, 1vh) * 98);
		}
	}
	.overlay.welcome .modal,
	.overlay.tuto .modal{
		height: auto !important;
		inset: inherit;
		left: 50%;
		top: 50%;
		transform: translate(-50%,-50%);
		width: calc(var(--game-width) - 5vw);
	}

	@media (max-width: 500px) {  
		.modal {
			max-width: 100%;
			width: 100%;
			height: 100%;
		}
		.overlay.welcome .modal,
		.overlay.tuto .modal{
			height: 100vh;
			width: 95vw;
		}
	}
	@media (min-width: 500px) and (max-height: 800px) {  
		.modal {
			position: absolute;
			left: 10vw;
			width: 80vw;
		}
	}

	@media (max-width: 375px) and (max-height: 700px) {   // Iphone 6

		.overlay.welcome .modal,
		.overlay.tuto .modal{
			height: 100vh;
			width: 95vw;
		}
		div{
			font-size: 10px;
		}

	}

	/* fullscreen modal */
	.page {
		@include modal-outer();
		background: var(--bg-primary);
		top: 10vh;
		height: 100%;
		max-width: var(--game-width);
		margin: auto;
		padding: 10px 16px;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		overflow-y: auto;
		.exit {
			top: 10px;
			right: 10px;
		}
	}
	:global([slot="footer"]) {
		color: var(--fg-secondary);
		display: flex;
		justify-content: space-between;
		align-items: flex-end;
		text-align: end;
	}
</style>
