<script lang="ts">
  import {
    modeData,
    seededRandomInt,
    createDefaultStats,
    createNewGame,
    createDefaultSettings,
    createLetterStates,
    ROWS,
    getWordNumber,
    words,
    familys,
    resetTuto,
  } from "./utils";

  let introView = true;
  const dev = localStorage.getItem("dev");
  const reloadautowin = localStorage.getItem("reloadautowin");
  if (localStorage.getItem("reload") || dev === "true" || reloadautowin) {
    introView = false;
    localStorage.removeItem("reload");
  } else {
    resetTuto();
  }

  // DEV
  // introView = false;

  import Game from "./components/Game.svelte";
  import { letterStates, settings, mode } from "./stores";
  import { GameMode } from "./enums";
  import { Toaster } from "./components/widgets";
  import { setContext } from "svelte";

  import Famille from "./components/Famille.svelte";
  import Intro from "./components/Intro.svelte";
  import Logo from "./components/Logo.svelte";

  export let version: string;
  setContext("version", version);
  localStorage.setItem("version", version);

  let stats: Stats;
  let word: string;
  let state: GameState;
  settings.set(
    (JSON.parse(localStorage.getItem("settings")) as Settings) ||
      createDefaultSettings()
  );
  settings.subscribe((s) =>
    localStorage.setItem("settings", JSON.stringify(s))
  );

  let hash = window.location.hash.slice(1).split("/");

  if (!localStorage.getItem("force-famille")) {
    let lastHash = "";
    if (
      localStorage.getItem("famille") &&
      localStorage.getItem("famille") != ""
    ) {
      lastHash = localStorage.getItem("famille");
    }

    if (hash[0] == "" && lastHash == "") {
      // console.log('pas hash, pas lastHash')
      hash = randomFamilleExl();
    } else if (hash[0] != "" && lastHash == "") {
      // console.log('un hash, pas lastHash')
      hash = hash[0];
      history.pushState(
        "",
        document.title,
        window.location.pathname + window.location.search
      );
    } else if (hash[0] == "" && lastHash != "") {
      // console.log('pas hash, un lastHash')
      hash = randomFamilleExl(lastHash);
    } else if (hash[0] != "" && lastHash != "") {
      // console.log('un hash, un lastHash')
      hash = hash[0];
      history.pushState(
        "",
        document.title,
        window.location.pathname + window.location.search
      );
    }

    localStorage.setItem("famille", hash);
  }
  // console.log('new', hash)

  function randomFamilleExl(exlu = null) {
    const familles = ["re-source", "re-cherche", "re-cycle", "re-pousse"];
    let key = Math.floor(Math.random() * familles.length);

    if (exlu) {
      if (familles[key] == exlu) {
        key = Math.floor(Math.random() * familles.length);
      }
    }

    return familles[key];
  }

  let COLS = 0;

  const modeVal: GameMode = !isNaN(GameMode[hash[0]])
    ? GameMode[hash[0]]
    : parseInt(localStorage.getItem("mode")) || modeData.default;
  mode.set(modeVal);

  const familyNumbers = allWordsNumber();

  function allWordsNumber() {
    let familysNumbers = [];
    let tempWords = [];

    for (const [family, value] of Object.entries(familys)) {
      const words = value["words"];
      for (let index = 0; index < words.length; index++) {
        tempWords.push(index);
      }
      familysNumbers.push(tempWords);
      tempWords = [];
    }
    return familysNumbers;
  }

  function getKeyFamily(hash) {
    let key = 0;
    let i = 0;
    for (const [family, value] of Object.entries(familys)) {
      if (family === hash) {
        key = i;
      }
      i++;
    }
    return key;
  }

  function getWordRandomInFamilyNumbers(arr) {
    var key = arr[Math.floor(Math.random() * arr.length)];
    return key;
  }

  function getWord(m, hash) {
    let neverPlayed = [];
    if (localStorage.getItem("neverPlayed")) {
      neverPlayed = JSON.parse(localStorage.getItem("neverPlayed"));
    } else {
      localStorage.setItem("neverPlayed", JSON.stringify(familyNumbers));
      neverPlayed = familyNumbers;
    }

    const keyFamily = getKeyFamily(hash);
    let newFamilyNumbers = neverPlayed[keyFamily];
    let keyWord = getWordRandomInFamilyNumbers(newFamilyNumbers);

    let newWord = "";
    // RESET Family
    if (keyWord === undefined) {
      newFamilyNumbers = familyNumbers[keyFamily];
      keyWord = getWordRandomInFamilyNumbers(newFamilyNumbers);
    }

    newWord = familys[hash].words[keyWord]?.word;

    if (newWord === undefined) {
      window.location.reload();
    }

    // Set new array
    let setWordsNumbers = [];
    newFamilyNumbers.forEach((key) => {
      if (key != keyWord) {
        setWordsNumbers.push(key);
      }
    });

    let setFamilyNumers = [];
    let setFamilyNumersI = 0;
    neverPlayed.forEach((key) => {
      if (setFamilyNumersI === keyFamily) {
        setFamilyNumers.push(setWordsNumbers);
      } else {
        setFamilyNumers.push(key);
      }

      setFamilyNumersI++;
    });

    localStorage.setItem("neverPlayed", JSON.stringify(setFamilyNumers));

    return newWord;
  }

  mode.subscribe((m) => {
    localStorage.setItem("mode", `${m}`);
    stats =
      (JSON.parse(localStorage.getItem(`stats-${m}`)) as Stats) ||
      createDefaultStats(m);

    word = getWord(m, hash);

    // console.log('word ok:', word)

    COLS = word.length;

    let temp: GameState;
    if (modeData.modes[m].historical === true) {
      temp = JSON.parse(localStorage.getItem(`state-${m}-h`));
      if (!temp || temp.wordNumber !== getWordNumber(m)) {
        state = createNewGame(m);
      } else {
        state = temp;
      }
    } else {
      temp = JSON.parse(localStorage.getItem(`state-${m}`));
      if (
        !temp ||
        modeData.modes[m].seed - temp.time >= modeData.modes[m].unit
      ) {
        state = createNewGame(m);
      } else {
        // This is for backwards compatibility, can be removed in a day
        if (!temp.wordNumber) {
          temp.wordNumber = getWordNumber(m);
        }
        state = temp;
      }
    }
    // Set the letter states when data for a new game mode is loaded so the keyboard is correct
    const letters = createLetterStates();
    for (let row = 0; row < ROWS; ++row) {
      for (let col = 0; col < state.board.words[row].length; ++col) {
        if (
          letters[state.board.words[row][col]] === "🔳" ||
          state.board.state[row][col] === "🟩"
        ) {
          letters[state.board.words[row][col]] = state.board.state[row][col];
        }
      }
    }
    letterStates.set(letters);
  });

  $: saveState(state);
  function saveState(state: GameState) {
    if (modeData.modes[$mode].historical) {
      localStorage.setItem(`state-${$mode}-h`, JSON.stringify(state));
    } else {
      localStorage.setItem(`state-${$mode}`, JSON.stringify(state));
    }
  }
  let toaster: Toaster;

  document.title = "REGAIN | croixmariebourdon";

  console.log("👋 Version : 2024-05-31");
</script>

<Toaster bind:this={toaster} />
{#if toaster}
  <Logo />

  {#if introView}
    <Intro {hash} />
  {/if}
  <Famille {hash} />
  <Game {stats} {word} {toaster} bind:game={state} />
{/if}
