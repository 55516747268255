<script lang="ts">
    import { familyNames } from "../utils";
    import { gsap, Power4 } from "gsap";
    import { onMount } from 'svelte';

	export let hash: string;

    let newFamilyNames = null;

    let newHash = '';
    if(Array.isArray(hash)){
        newHash = hash[0]
    }else{
        newHash = hash;
    }

    newFamilyNames = [newHash];
    familyNames.forEach(family => {
        if(!newFamilyNames.includes(family)){
            newFamilyNames.push(family)
        }
    });
    newFamilyNames = newFamilyNames.reverse();

    onMount(() => {


        const pathGlogo = [...document.querySelectorAll('svg.top path, svg.top g')];

        const logo = [];
        pathGlogo.forEach(el => {
            if(el.id){
                logo.push(el)
            }
        });

        const tl = gsap.timeline();




		tl.to(logo, {
			duration: 0.2,
		})

		tl.to(logo, {
			duration: 1,
            opacity:1,
            y:0,
            x:0,
            rotate:'0deg',
            ease: Power4.easeOut,
            stagger: { 
                each: 0.2,
            }
		})

		tl.to('.intro img', {
			duration: 1,
            x: '0',
            y: '0',
            rotation:'0deg',
            stagger: { 
                each: 0.3,
            }
		})

		tl.to('.card:nth-child(1) img', {
			duration: 1,
            rotation:'25deg',
            ease: Power4.easeInOut,
		})
		tl.to('.card:nth-child(2) img', {
			duration: 1,
            rotation:'20deg',
            ease: Power4.easeInOut,
		}, "<")
		tl.to('.card:nth-child(3) img', {
			duration: 1,
            rotation:'13deg',
            ease: Power4.easeInOut,
		}, "<")
		tl.to('.card:nth-child(4) img', {
			duration: 1,
            rotation:'5deg',
            ease: Power4.easeInOut,
		}, "<")
        tl.to('.card', {
			duration: 1,
            x:'-10%',
            rotation:'-20deg',
            ease: Power4.easeInOut,
		}, "<")

		tl.to('.card img', {
			duration: 1,
            rotation:'0deg',
            ease: Power4.easeInOut,
		})
        tl.to('.intro .card', {
			duration: 1,
            x:'0%',
            rotation:'0deg',
            ease: Power4.easeInOut,
		}, "<")

		tl.to('.intro img', {
			duration: 1,
            ease: Power4.easeIn,
            y: '100vh',
            stagger: { 
                each: 0.2,
                from:"end",
            }
		})

		tl.to('.intro .bck', {
			duration: 1,
            ease: Power4.easeInOut,
            opacity: 0,

		}, "-=0.5")
		tl.to('svg.top', {
			duration: 1,
            ease: Power4.easeInOut,
            opacity: 0,
		}, "<")
		tl.to('.intro', {
			duration: 0.1,
            autoAlpha: 0,
		})
		tl.to('.logo .top', {
			duration: 0.1,
            autoAlpha: 0,
		})
	});

</script>

	<div class="intro">
        <div class="bck"></div>
        <div class="int">
            {#each newFamilyNames as family}
            <div class="card">
                <img width="863" height="1335" src="./img/cartes/{family}.png" alt="">
            </div>
            {/each}
        </div>
	</div>

<style lang="scss">

    .bck, .int{
        width:100vw;
        height:100vh;
        height: calc(var(--vh, 1vh) * 100);
        overflow: hidden;
        pointer-events: none;
    }
    .bck{
        position: absolute;
        left: 0;
        top: 0;
        z-index: 2;
        background: #ede7d5;
    }

    .int{
        position: absolute;
        left: 0;
        top: 0;
        z-index: 2;
    }
    .card{
        position:absolute;
        left:50%;
        top:50%;

        width: 400px;
        height: 80vh;
        height: calc(var(--vh, 1vh) * 80);

        transform:translate(-50%, -50%);

        display: flex;
        justify-content: center;

    }

    img{
        height: 100%;
        width: 100%;
        object-fit: contain;
        overflow:hidden;

        transform:translate( -100vw, 50vw) rotate(-20deg);
        transform-origin: center bottom;

    }




</style>
