const words = {
    data: [
        {
            id: 2,
            attributes: {
                name: "TERRE",
                projet_name: "GUIRE",
                projet_link: "https://www.croixmariebourdon.fr/?work=construction-dune-bibliotheque-guire-yero-bocar-casamance-senegal",
                texte_projet: "Les maçonneries de la bibliothèque de Guiré-Yéro-Bocar sont réalisées en briques de terre crue compressée (BTC) issue des terrassements du chantier.",
                createdAt: "2023-01-17T13:35:32.460Z",
                updatedAt: "2023-01-24T16:49:45.473Z",
                projet_indice: "Couche superficielle du globe",
                publishedAt: "2023-01-17T13:35:32.460Z",
                famille: {
                    data: {
                        id: 1,
                        attributes: {
                            name: "re-source",
                            createdAt: "2023-01-17T13:32:09.250Z",
                            updatedAt: "2023-01-24T14:54:52.672Z",
                            publishedAt: "2023-01-17T13:32:09.969Z",
                            indice: "matériaux sains, biosourcés, construire local, circuit court, artisanat, fait-main",
                        },
                    },
                },
            },
        },
        {
            id: 3,
            attributes: {
                name: "BAMBOU",
                projet_name: "GUIRE",
                projet_link: "https://www.croixmariebourdon.fr/?work=construction-dune-bibliotheque-guire-yero-bocar-casamance-senegal",
                texte_projet: "Les plafonds et les cloisons de la bibliothèque du lycée de Guiré-Yéro-Bocar, en Casamance, sont réalisés en krintin, bambou tressé local.",
                createdAt: "2023-01-17T13:35:53.764Z",
                updatedAt: "2023-01-24T15:01:54.004Z",
                projet_indice: "Graminée arborescente",
                publishedAt: "2023-01-17T13:35:53.764Z",
                famille: {
                    data: {
                        id: 1,
                        attributes: {
                            name: "re-source",
                            createdAt: "2023-01-17T13:32:09.250Z",
                            updatedAt: "2023-01-24T14:54:52.672Z",
                            publishedAt: "2023-01-17T13:32:09.969Z",
                            indice: "matériaux sains, biosourcés, construire local, circuit court, artisanat, fait-main",
                        },
                    },
                },
            },
        },
        {
            id: 4,
            attributes: {
                name: "BRIQUE",
                projet_name: "ABORDS",
                projet_link: "https://www.croixmariebourdon.fr/?work=amenagement-urbain-dun-ilot-de-fraicheur-malakoff",
                texte_projet: "Les briques à joints sablés forment un sol polyvalent et infiltrant pour les cours de récréation du groupe scolaire Paulette Nardal et leurs abords.",
                createdAt: "2023-01-17T13:36:12.224Z",
                updatedAt: "2023-01-24T15:04:39.994Z",
                projet_indice: "Matériau constitué de terre argileuse.",
                publishedAt: "2023-01-17T13:36:12.224Z",
                famille: {
                    data: {
                        id: 1,
                        attributes: {
                            name: "re-source",
                            createdAt: "2023-01-17T13:32:09.250Z",
                            updatedAt: "2023-01-24T14:54:52.672Z",
                            publishedAt: "2023-01-17T13:32:09.969Z",
                            indice: "matériaux sains, biosourcés, construire local, circuit court, artisanat, fait-main",
                        },
                    },
                },
            },
        },
        {
            id: 5,
            attributes: {
                name: "OASIS",
                projet_name: "ABORDS",
                projet_link: "https://www.croixmariebourdon.fr/?work=amenagement-urbain-dun-ilot-de-fraicheur-malakoff",
                texte_projet: "A Malakoff, les cours oasis et la rue jardin renforcent la biodiversité et forment un îlot de fraîcheur aux abords du groupe scolaire.",
                createdAt: "2023-01-17T13:36:29.808Z",
                updatedAt: "2023-01-25T11:43:31.812Z",
                projet_indice: "Lieu de fraîcheur",
                publishedAt: "2023-01-17T13:36:29.808Z",
                famille: {
                    data: {
                        id: 2,
                        attributes: {
                            name: "re-pousse",
                            createdAt: "2023-01-17T13:32:18.498Z",
                            updatedAt: "2023-01-24T14:55:02.536Z",
                            publishedAt: "2023-01-17T13:32:19.103Z",
                            indice: "semer, replanter, nature en ville, biodiversité, ilot de fraicheur, vivant, plantes",
                        },
                    },
                },
            },
        },
        {
            id: 7,
            attributes: {
                name: "TALUS",
                projet_name: "LE MANS",
                projet_link: "https://www.croixmariebourdon.fr/?work=batiment-de-stockage",
                texte_projet: "Par sa construction semi-enterrée, le bâtiment de stockage du Mans profite d'une température constante en toute saison.",
                createdAt: "2023-01-17T13:37:02.318Z",
                updatedAt: "2023-01-24T15:11:21.664Z",
                projet_indice: "Terrain en forte pente",
                publishedAt: "2023-01-17T13:37:02.318Z",
                famille: {
                    data: {
                        id: 3,
                        attributes: {
                            name: "re-cherche",
                            createdAt: "2023-01-17T13:32:24.884Z",
                            updatedAt: "2023-01-24T14:54:44.333Z",
                            publishedAt: "2023-01-17T13:32:25.412Z",
                            indice: "innover, inventer, dispositifs, bioclimatique, résilience, résister au changement",
                        },
                    },
                },
            },
        },
        {
            id: 12,
            attributes: {
                name: "ECUREUIL",
                projet_name: "CHARONNE",
                projet_link: "https://www.croixmariebourdon.fr/?work=restructuration-dune-residence-sociale",
                texte_projet: "A Paris, rue de Charonne, la restructutation offre des logements de grande qualité tout en valorisant un refuge de biodiversité en cœur d'ilot.",
                createdAt: "2023-01-17T13:38:12.099Z",
                updatedAt: "2023-06-13T09:50:27.828Z",
                projet_indice: "Petit rongeur arboricole",
                publishedAt: "2023-01-17T13:38:12.099Z",
                famille: {
                    data: {
                        id: 2,
                        attributes: {
                            name: "re-pousse",
                            createdAt: "2023-01-17T13:32:18.498Z",
                            updatedAt: "2023-01-24T14:55:02.536Z",
                            publishedAt: "2023-01-17T13:32:19.103Z",
                            indice: "semer, replanter, nature en ville, biodiversité, ilot de fraicheur, vivant, plantes",
                        },
                    },
                },
            },
        },
        {
            id: 13,
            attributes: {
                name: "BOIS",
                projet_name: "LASA",
                projet_link: "https://www.croixmariebourdon.fr/?work=construction-dun-immeuble-de-bureaux-malakoff-2",
                texte_projet: "A Malakoff, la construction de bureaux en charpente bois traditionnelle permet de proposer des plateaux mutables, libérés de tout porteurs.",
                createdAt: "2023-01-17T13:38:28.440Z",
                updatedAt: "2023-01-24T15:02:32.651Z",
                projet_indice: "Matière dure formée par des vaisseaux.",
                publishedAt: "2023-01-17T13:38:28.440Z",
                famille: {
                    data: {
                        id: 1,
                        attributes: {
                            name: "re-source",
                            createdAt: "2023-01-17T13:32:09.250Z",
                            updatedAt: "2023-01-24T14:54:52.672Z",
                            publishedAt: "2023-01-17T13:32:09.969Z",
                            indice: "matériaux sains, biosourcés, construire local, circuit court, artisanat, fait-main",
                        },
                    },
                },
            },
        },
        {
            id: 15,
            attributes: {
                name: "FRICHE",
                projet_name: "LE MANS",
                projet_link: "https://www.croixmariebourdon.fr/?work=batiment-de-stockage",
                texte_projet: "Au Mans, l'implantation du nouvel entrepôt vise à renforcer le corridor écologique constitué par les friches ferroviaires et militaires.",
                createdAt: "2023-01-17T13:38:51.923Z",
                updatedAt: "2023-01-25T11:41:45.857Z",
                projet_indice: "Terrain disponible à la reconquête",
                publishedAt: "2023-01-17T13:38:51.923Z",
                famille: {
                    data: {
                        id: 2,
                        attributes: {
                            name: "re-pousse",
                            createdAt: "2023-01-17T13:32:18.498Z",
                            updatedAt: "2023-01-24T14:55:02.536Z",
                            publishedAt: "2023-01-17T13:32:19.103Z",
                            indice: "semer, replanter, nature en ville, biodiversité, ilot de fraicheur, vivant, plantes",
                        },
                    },
                },
            },
        },
        {
            id: 19,
            attributes: {
                name: "MOINEAU",
                projet_name: "DROUOT",
                projet_link: "https://www.croixmariebourdon.fr/?work=pole-logistique-et-local-commercial-paris-18e",
                texte_projet: "En collaboration avec la LPO, les travaux en site occupé respectent la présence des colonies de moineaux vivant en façades.",
                createdAt: "2023-01-17T13:39:52.303Z",
                updatedAt: "2024-05-23T09:47:48.805Z",
                projet_indice: "Petit passereau d'Eurasie autrefois commun",
                publishedAt: "2023-01-17T13:39:52.303Z",
                famille: {
                    data: {
                        id: 2,
                        attributes: {
                            name: "re-pousse",
                            createdAt: "2023-01-17T13:32:18.498Z",
                            updatedAt: "2023-01-24T14:55:02.536Z",
                            publishedAt: "2023-01-17T13:32:19.103Z",
                            indice: "semer, replanter, nature en ville, biodiversité, ilot de fraicheur, vivant, plantes",
                        },
                    },
                },
            },
        },
        {
            id: 21,
            attributes: {
                name: "LUMIERE",
                projet_name: "BOULOGNE",
                projet_link: "https://www.croixmariebourdon.fr/?work=residence-sociale-de-115-logements-boulogne-billancourt-2",
                texte_projet: "A Boulogne, la restructuration/extension a permis de créer des logements de qualité en valorisant les apports de lumière naturelle.",
                createdAt: "2023-01-24T15:00:42.500Z",
                updatedAt: "2023-01-24T15:00:42.500Z",
                projet_indice: "Qui rend les objets visibles",
                publishedAt: "2023-01-24T15:00:42.500Z",
                famille: {
                    data: {
                        id: 3,
                        attributes: {
                            name: "re-cherche",
                            createdAt: "2023-01-17T13:32:24.884Z",
                            updatedAt: "2023-01-24T14:54:44.333Z",
                            publishedAt: "2023-01-17T13:32:25.412Z",
                            indice: "innover, inventer, dispositifs, bioclimatique, résilience, résister au changement",
                        },
                    },
                },
            },
        },
        {
            id: 22,
            attributes: {
                name: "DENSITE",
                projet_name: "BOULOGNE",
                projet_link: "https://www.croixmariebourdon.fr/?work=residence-sociale-de-115-logements-boulogne-billancourt-2",
                texte_projet: "A Boulogne, la valorisation du bâti existant permet de limiter la construction en cœur d'ilot (insertion, nature en ville)",
                createdAt: "2023-01-24T15:03:11.981Z",
                updatedAt: "2023-01-25T11:43:07.669Z",
                projet_indice: "Ce qui contient beaucoup de matière en peu de place",
                publishedAt: "2023-01-24T15:03:11.981Z",
                famille: {
                    data: {
                        id: 4,
                        attributes: {
                            name: "re-cycle",
                            createdAt: "2023-01-17T13:32:52.260Z",
                            updatedAt: "2023-01-24T14:55:12.205Z",
                            publishedAt: "2023-01-17T13:32:52.915Z",
                            indice: "réemploi, recycler, changer d’usage, évolutivité, réhabiliter, recharger, transformer, bricoler, réparer, chiner",
                        },
                    },
                },
            },
        },
        {
            id: 23,
            attributes: {
                name: "DRAINAGE",
                projet_name: "ABORDS",
                projet_link: "https://www.croixmariebourdon.fr/?work=amenagement-urbain-dun-ilot-de-fraicheur-malakoff",
                texte_projet: "Les briques à joints sablés forment un sol polyvalent et infiltrant pour les cours de récréation du groupe scolaire Paulette Nardal et leurs abords.",
                createdAt: "2023-01-24T15:05:21.759Z",
                updatedAt: "2023-01-24T15:05:21.759Z",
                projet_indice: "Dispositif favorisant l'évacuation",
                publishedAt: "2023-01-24T15:05:21.759Z",
                famille: {
                    data: {
                        id: 3,
                        attributes: {
                            name: "re-cherche",
                            createdAt: "2023-01-17T13:32:24.884Z",
                            updatedAt: "2023-01-24T14:54:44.333Z",
                            publishedAt: "2023-01-17T13:32:25.412Z",
                            indice: "innover, inventer, dispositifs, bioclimatique, résilience, résister au changement",
                        },
                    },
                },
            },
        },
        {
            id: 24,
            attributes: {
                name: "ENTRAIT",
                projet_name: "MARCHE CACHAN",
                projet_link: "https://www.croixmariebourdon.fr/?work=halle-de-marche-cachan",
                texte_projet: "A Cachan, la charpente assure un franchissement de 30 mètres. Elle supporte les installations et libère l’espace utile de tout élément porteur.",
                createdAt: "2023-01-24T15:06:47.728Z",
                updatedAt: "2023-01-24T15:06:47.728Z",
                projet_indice: "Base de la ferme",
                publishedAt: "2023-01-24T15:06:47.728Z",
                famille: {
                    data: {
                        id: 3,
                        attributes: {
                            name: "re-cherche",
                            createdAt: "2023-01-17T13:32:24.884Z",
                            updatedAt: "2023-01-24T14:54:44.333Z",
                            publishedAt: "2023-01-17T13:32:25.412Z",
                            indice: "innover, inventer, dispositifs, bioclimatique, résilience, résister au changement",
                        },
                    },
                },
            },
        },
        {
            id: 25,
            attributes: {
                name: "ECAILLES",
                projet_name: "MOVAU",
                projet_link: "https://www.croixmariebourdon.fr/?work=rehabilitation-de-logements-en-site-occupe-paris-11e",
                texte_projet: "A Paris, au cœur du 11e arrondissement, la nouvelle vêture en écailles minérales offre une insertion renouvelée et durable.",
                createdAt: "2023-01-24T15:08:30.419Z",
                updatedAt: "2023-01-24T15:08:30.419Z",
                projet_indice: "Éléments d'enveloppe à recouvrement",
                publishedAt: "2023-01-24T15:08:30.419Z",
                famille: {
                    data: {
                        id: 4,
                        attributes: {
                            name: "re-cycle",
                            createdAt: "2023-01-17T13:32:52.260Z",
                            updatedAt: "2023-01-24T14:55:12.205Z",
                            publishedAt: "2023-01-17T13:32:52.915Z",
                            indice: "réemploi, recycler, changer d’usage, évolutivité, réhabiliter, recharger, transformer, bricoler, réparer, chiner",
                        },
                    },
                },
            },
        },
        {
            id: 26,
            attributes: {
                name: "ISOLER",
                projet_name: "MATERNELLE BERT",
                projet_link: "https://www.croixmariebourdon.fr/?work=restructuration-dune-ecole-maternelle-malakoff-2",
                texte_projet: "L'école maternelle Paulette Nardal fait l'objet d'une rénovation énergétique  associant isolation renforcée et réfection des systèmes.",
                createdAt: "2023-01-24T15:08:58.997Z",
                updatedAt: "2023-01-24T15:08:58.997Z",
                projet_indice: "Mettre à l'abri",
                publishedAt: "2023-01-24T15:08:58.997Z",
                famille: {
                    data: {
                        id: 3,
                        attributes: {
                            name: "re-cherche",
                            createdAt: "2023-01-17T13:32:24.884Z",
                            updatedAt: "2023-01-24T14:54:44.333Z",
                            publishedAt: "2023-01-17T13:32:25.412Z",
                            indice: "innover, inventer, dispositifs, bioclimatique, résilience, résister au changement",
                        },
                    },
                },
            },
        },
        {
            id: 27,
            attributes: {
                name: "ESPACE",
                projet_name: "PANTIN",
                projet_link: "https://www.croixmariebourdon.fr/?work=transformation-de-bureaux-en-70-logements-pantin",
                texte_projet: "A Pantin, la transformation des plateaux de bureaux  tire le meilleur des  existants pour offrir des logements de grande qualité et de nouveaux usages.",
                createdAt: "2023-01-24T15:09:52.695Z",
                updatedAt: "2023-01-24T15:09:52.695Z",
                projet_indice: "Etendue destinée à un usage",
                publishedAt: "2023-01-24T15:09:52.695Z",
                famille: {
                    data: {
                        id: 4,
                        attributes: {
                            name: "re-cycle",
                            createdAt: "2023-01-17T13:32:52.260Z",
                            updatedAt: "2023-01-24T14:55:12.205Z",
                            publishedAt: "2023-01-17T13:32:52.915Z",
                            indice: "réemploi, recycler, changer d’usage, évolutivité, réhabiliter, recharger, transformer, bricoler, réparer, chiner",
                        },
                    },
                },
            },
        },
        {
            id: 28,
            attributes: {
                name: "OMBRE",
                projet_name: "COLLET ",
                projet_link: "https://www.croixmariebourdon.fr/?work=renovation-dune-ecole-maternelle-chevilly-larue",
                texte_projet: "A Chevilly, la galerie périphérique procure, au fil des saisons, une ombre adaptée aux espaces d'enseignement intérieurs et extérieurs.",
                createdAt: "2023-01-24T15:10:54.344Z",
                updatedAt: "2023-01-25T11:43:54.207Z",
                projet_indice: "Interception de la lumière",
                publishedAt: "2023-01-24T15:10:54.344Z",
                famille: {
                    data: {
                        id: 3,
                        attributes: {
                            name: "re-cherche",
                            createdAt: "2023-01-17T13:32:24.884Z",
                            updatedAt: "2023-01-24T14:54:44.333Z",
                            publishedAt: "2023-01-17T13:32:25.412Z",
                            indice: "innover, inventer, dispositifs, bioclimatique, résilience, résister au changement",
                        },
                    },
                },
            },
        },
        {
            id: 29,
            attributes: {
                name: "TERRASSE",
                projet_name: "VIRY CHATILLON",
                projet_link: "https://www.croixmariebourdon.fr/?work=bureaux-espace-social-et-logements-viry-chatillon-2",
                texte_projet: "A Viry, les toitures de l'opération forment des jardins suspendus qui contribuent à l'agrément du quartier et au renfort de la biodiversité.",
                createdAt: "2023-01-24T16:50:17.875Z",
                updatedAt: "2023-01-24T16:50:17.875Z",
                projet_indice: "Palier aménagé pour la culture",
                publishedAt: "2023-01-24T16:50:17.875Z",
                famille: {
                    data: {
                        id: 2,
                        attributes: {
                            name: "re-pousse",
                            createdAt: "2023-01-17T13:32:18.498Z",
                            updatedAt: "2023-01-24T14:55:02.536Z",
                            publishedAt: "2023-01-17T13:32:19.103Z",
                            indice: "semer, replanter, nature en ville, biodiversité, ilot de fraicheur, vivant, plantes",
                        },
                    },
                },
            },
        },
        {
            id: 30,
            attributes: {
                name: "CHEMINEE",
                projet_name: "COURBEVOIE",
                projet_link: "https://www.croixmariebourdon.fr/?work=halle-de-marche-parking-public-et-esplanade-courbevoie",
                texte_projet: "Au Marché de Courbevoie, la conception du volume et des dispositifs valorise la ventilation naturelle au service d'un rafraichissement passif.",
                createdAt: "2023-01-24T16:50:44.787Z",
                updatedAt: "2023-01-24T16:50:44.787Z",
                projet_indice: "Conduit de tirage",
                publishedAt: "2023-01-24T16:50:44.787Z",
                famille: {
                    data: {
                        id: 3,
                        attributes: {
                            name: "re-cherche",
                            createdAt: "2023-01-17T13:32:24.884Z",
                            updatedAt: "2023-01-24T14:54:44.333Z",
                            publishedAt: "2023-01-17T13:32:25.412Z",
                            indice: "innover, inventer, dispositifs, bioclimatique, résilience, résister au changement",
                        },
                    },
                },
            },
        },
        {
            id: 31,
            attributes: {
                name: "PROTEGER",
                projet_name: "CRETEIL",
                projet_link: "https://www.croixmariebourdon.fr/?work=37-logements-en-accession-creteil",
                texte_projet: "Situé en secteur inondable, le projet de Créteil propose une approche résiliente des contraintes de nivellement et de protection.",
                createdAt: "2023-01-24T16:51:13.562Z",
                updatedAt: "2023-01-24T16:51:13.562Z",
                projet_indice: "Mettre hors de danger",
                publishedAt: "2023-01-24T16:51:13.562Z",
                famille: {
                    data: {
                        id: 3,
                        attributes: {
                            name: "re-cherche",
                            createdAt: "2023-01-17T13:32:24.884Z",
                            updatedAt: "2023-01-24T14:54:44.333Z",
                            publishedAt: "2023-01-17T13:32:25.412Z",
                            indice: "innover, inventer, dispositifs, bioclimatique, résilience, résister au changement",
                        },
                    },
                },
            },
        },
        {
            id: 32,
            attributes: {
                name: "JARDIN",
                projet_name: "BOULOGNE",
                projet_link: "https://www.croixmariebourdon.fr/?work=residence-sociale-de-115-logements-boulogne-billancourt-2",
                texte_projet: "L'opération de Boulogne valorise le foncier existant, par le réaménagement des étages courants et par une surélévation d’un niveau afin de préserver la pleine terre au coeur d'ilôt. ",
                createdAt: "2023-01-24T16:51:43.270Z",
                updatedAt: "2023-01-24T16:51:43.270Z",
                projet_indice: "Terrain clos",
                publishedAt: "2023-01-24T16:51:43.270Z",
                famille: {
                    data: {
                        id: 2,
                        attributes: {
                            name: "re-pousse",
                            createdAt: "2023-01-17T13:32:18.498Z",
                            updatedAt: "2023-01-24T14:55:02.536Z",
                            publishedAt: "2023-01-17T13:32:19.103Z",
                            indice: "semer, replanter, nature en ville, biodiversité, ilot de fraicheur, vivant, plantes",
                        },
                    },
                },
            },
        },
        {
            id: 33,
            attributes: {
                name: "APPORTS",
                projet_name: "VARLIN",
                projet_link: "https://www.croixmariebourdon.fr/?work=maison-bois-sceaux",
                texte_projet: "A Malakoff, la volumétrie de cette maison mon-orientée nord-est est retravaillée pour valoriser les apports solaires.",
                createdAt: "2023-01-24T16:52:06.896Z",
                updatedAt: "2023-01-24T16:52:06.896Z",
                projet_indice: "Contributions énergétiques",
                publishedAt: "2023-01-24T16:52:06.896Z",
                famille: {
                    data: {
                        id: 3,
                        attributes: {
                            name: "re-cherche",
                            createdAt: "2023-01-17T13:32:24.884Z",
                            updatedAt: "2023-01-24T14:54:44.333Z",
                            publishedAt: "2023-01-17T13:32:25.412Z",
                            indice: "innover, inventer, dispositifs, bioclimatique, résilience, résister au changement",
                        },
                    },
                },
            },
        },
        {
            id: 34,
            attributes: {
                name: "APPORTS",
                projet_name: "VARLIN",
                projet_link: "https://www.croixmariebourdon.fr/?work=maison-bois-sceaux",
                texte_projet: "A Malakoff, la volumétrie de cette maison mon-orientée nord-est est retravaillée pour valoriser les apports solaires.",
                createdAt: "2023-01-24T16:52:26.335Z",
                updatedAt: "2023-01-24T16:52:26.335Z",
                projet_indice: "Contributions énergétiques",
                publishedAt: "2023-01-24T16:52:26.335Z",
                famille: {
                    data: {
                        id: 3,
                        attributes: {
                            name: "re-cherche",
                            createdAt: "2023-01-17T13:32:24.884Z",
                            updatedAt: "2023-01-24T14:54:44.333Z",
                            publishedAt: "2023-01-17T13:32:25.412Z",
                            indice: "innover, inventer, dispositifs, bioclimatique, résilience, résister au changement",
                        },
                    },
                },
            },
        },
        {
            id: 35,
            attributes: {
                name: "POTAGER",
                projet_name: "BLUM",
                projet_link: "https://www.croixmariebourdon.fr/?work=10-logements-semi-collectifs-jouy-en-josas",
                texte_projet: "A Jouy-en-Josas, les deux cours sont reliées par des jardins potagers mis à la disposition des logements collectifs.",
                createdAt: "2023-01-24T16:52:51.459Z",
                updatedAt: "2023-01-24T16:52:51.459Z",
                projet_indice: "Parcelle cultivée",
                publishedAt: "2023-01-24T16:52:51.459Z",
                famille: {
                    data: {
                        id: 2,
                        attributes: {
                            name: "re-pousse",
                            createdAt: "2023-01-17T13:32:18.498Z",
                            updatedAt: "2023-01-24T14:55:02.536Z",
                            publishedAt: "2023-01-17T13:32:19.103Z",
                            indice: "semer, replanter, nature en ville, biodiversité, ilot de fraicheur, vivant, plantes",
                        },
                    },
                },
            },
        },
        {
            id: 36,
            attributes: {
                name: "BOSQUET",
                projet_name: "BLUM",
                projet_link: "https://www.croixmariebourdon.fr/?work=10-logements-semi-collectifs-jouy-en-josas",
                texte_projet: "A Jouy-en-Josas, dans une ancienne friche boisée, les bâtiments s'insèrent entre les bosquets pour former des cours résidentielles protégées.",
                createdAt: "2023-01-24T16:53:15.293Z",
                updatedAt: "2023-01-24T16:53:15.293Z",
                projet_indice: "Groupement végétal",
                publishedAt: "2023-01-24T16:53:15.293Z",
                famille: {
                    data: {
                        id: 2,
                        attributes: {
                            name: "re-pousse",
                            createdAt: "2023-01-17T13:32:18.498Z",
                            updatedAt: "2023-01-24T14:55:02.536Z",
                            publishedAt: "2023-01-17T13:32:19.103Z",
                            indice: "semer, replanter, nature en ville, biodiversité, ilot de fraicheur, vivant, plantes",
                        },
                    },
                },
            },
        },
    ],
    meta: { pagination: { page: 1, pageSize: 25, pageCount: 3, total: 61 } },
};
export default words;
