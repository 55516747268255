<script lang="ts">
	export let hash: string;

    const familleBck = "./img/famille/"+hash+".png";
    const familleName = "./img/famille/"+hash+"-name.png";
    const familleIcon = "./img/famille/"+hash+"-icon.svg";
    const familleStats = "./img/famille/"+hash+"-stats.svg";


    // randomFamilleExl('re-pousse');

    // function randomFamilleExl(exlu = null){
    //     const familles = ['re-source', 're-cherche', 're-cycle', 're-pousse'];
    //     let key = Math.floor(Math.random() * familles.length);

    //     if(exlu){
    //         if(familles[key] == exlu){
    //             key = Math.floor(Math.random() * familles.length);
    //         }
    //     }

    //     return familles[key];
    // }


</script>

	<div class="famille f-{hash}">
        <div class="left">
            <div class="center">
                <div class="icon" style="background-image:url({familleIcon})"></div>
                <div class="name" style="background-image:url({familleName})"></div>
            </div>
            <div class="stats" style="background-image:url({familleStats})"></div>
        </div>

        <div class="game"></div>

        <div class="right">
            <div class="famille-bck" style="background-image:url({familleBck})"></div>
        </div>
	</div>

<style lang="scss">
	.famille{
		position: absolute;
		z-index: -1;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: #f7f5eb;

        display: flex;
        justify-content: center;

        display: grid;
        grid-template-columns: 1fr var(--game-width) 1fr;
        overflow: hidden;
        
	}

    .left{

        height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: center;

    }
    .game{
        height: 100%;
    }
    .right{
        height: 100%;
    }


    .icon, .name, .stats, .famille-bck{
        background-position: center center;
        background-repeat: no-repeat;
        mix-blend-mode: multiply;
        background-size: contain;
    }

    .center{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
    }
    .icon{
        width: 15vw;
        height: 11vw;
        position: relative;
    }
    .name{
        width: 80%;
        height: 7vw;
    }

    .famille-bck{
        width: 90%;
        margin: 0 auto;
        height: 100%;
    }

    .stats{
        position: fixed;
        left: 2vw;
        bottom: 2vw;
        width: 250px;
        height: 150px;
        background-position: left;
        opacity: 0;
        transition: 1s opacity;
    }

	@media (max-width: 820px) {  
		.icon, .name, .stats, .right{
            display: none;
        }
        .famille{
            background-color: var(--blue);
        }
	}
	@media (min-width: 1050px) {  
        .stats{
            opacity: 1;
        }
    }

	@media (min-width: 1440px) {  
        .icon{
            width: 216px;
            height: 158px;
        }

        .name{
            width: 376px;
            height: 100px;
        }
        .famille-bck{
            width:90%;
        }
	}


</style>
